import React, { Component } from 'react';
import '../adminDash.css';
import { Button  } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { get} from '../../global_files/serverRequests';
import globalVariables from "../../global_files/globalVariables";
import { getFreshToken } from '../../global_files/auth';
import urls from '../../global_files/urls';
import AddIcon from '@material-ui/icons/Add';
import Customericon from '../../assets/customer-icon.png';
import Editicon from '../../assets/icons8-edit.png';
import Deleteicon from '../../assets/icons8-trash-50.png';
import download_icon from '../../assets/download_icon.png';
import ActivityTabs from "./ActivityTabs";
import axios from "axios";

class CustomerTab extends Component {

  constructor(props) {
    super(props);
    this.state = {
      customer_list:[],
      userr_id:[],
      openTicketCount:0,
      searchInput: '',
      currentPage: 1,
      rowsPerPage: 15,

      pending_ticket_count:0,
      
      open_ticket_count:0,
      assigned_ticket_count:0,
      closed_ticket_count:0,

      selectedTechnician:'',
      selectedFile: null
    };
  }

  // componentDidMount(){
  //   this.getCustomerList();
  //   this.validateSignedIn();
  // }

  async componentDidMount() {
    const isSignedIn = await this.validateSignedIn();
    if (isSignedIn) {
      this.getCustomerList();
    }
  }

validateSignedIn = async () => {
  let is_fresh_token = await getFreshToken()
  console.log("-------is_fresh_token----",is_fresh_token);
  if (!is_fresh_token) {
    alert("Your session has expired, please login again!!");
    window.location.replace('/login')
    return false;
  }
  return true;
  
}

  
  getCustomerList= async ()=>{

    let access_tocken = localStorage.getItem(globalVariables.AUTH_TOKEN)
    let dealer_uuid = localStorage.getItem(globalVariables.USER_ID)

    localStorage.setItem(globalVariables.USER_ID,dealer_uuid)

    console.log("----------access_tocken in customer list----------",access_tocken);
    console.log("----------dealer_uuid in customer list----------",dealer_uuid);
    let headers = {
      // 'content-type': 'application/json'
      'content-Type': 'application/json; charset=UTF-8',
      'Authorization': `Bearer ${access_tocken}`,
  }
  let response = await get(`${urls.BASE_URL}/api/users/${dealer_uuid}/user_customer_list/`, headers) 
    
      let data = response.results
        console.log("----------data in customer list----------",data);
        let userr_Id = [];
        if (data && data.length > 0) {
          userr_Id = data.map(customer => customer.user_id);
          console.log("*******************************user_Id****",userr_Id);
         
          this.setState({
            customer_list: data,
          });
          
          console.log("============ customer array : ",this.state.customer_list);
                    
        }
      }


  handleChangePage = (newPage) => {
          this.setState({
            currentPage: newPage,
          });
      };
  
  handleSearchInputChange = (e) => {
        this.setState({ searchInput: e.target.value });
      };    
  

  handleCustomerClick = (cust_id,cust_name) => {
        // Update the state with the selected user_id
            
      // localStorage.setItem(globalVariables.SELECTED_TECH_ID, tech_id);
      localStorage.setItem(globalVariables.SELECTED_CUSTOMER_NAME, cust_name);
      console.log("-------globalVariables.SELECTED_CUSTOMER_NAME-------", cust_name);
      };
      
      handleDelete =  async (selected_user_email) => {

        let access_tocken = localStorage.getItem(globalVariables.AUTH_TOKEN)

        let dealer_uuid = localStorage.getItem(globalVariables.USER_ID)
        // localStorage.setItem(globalVariables.USER_ID,dealer_uuid)
          
          console.log("----------access_tocken in handleDelete list----------",access_tocken)
          // const url = `${urls.BASE_URL}/api/tickets/ticket-delete/${ticket_id}/`;
          const userConfirmed = window.confirm('Are you sure you want to delete this user?');
  
          if (!userConfirmed) {
            return; // If the user clicks "Cancel" in the confirmation dialog, abort the delete operation
          }
          // let selected_user_email1="boyijel166@agafx.com" 
          try {
            let post_data = {
              "email" : selected_user_email
            }
            let data = JSON.stringify(post_data)

            const response = await fetch(`${urls.BASE_URL}/api/account/${dealer_uuid}/user_delete/`, {
              method: 'PATCH',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${access_tocken}`,
              },
              body: data
            });
            console.log("reponse ------------->", response);
            
            if (response.ok) {
              console.log(`User with email ${selected_user_email} deleted successfully`, response);
              alert(`User with email ${selected_user_email} deleted successfully`);
              window.location.reload();
              } 
            else {
            //   // Handle error cases
            //   console.error(`Failed to delete ticket with ID ${ticket_id}`); -----------------------
            const errorMessage = response.statusText; // Read the error message from response body
            // console.error(`Failed to delete user with ID ${SelectedUserId}: ${errorMessage}`);
            // // Handle the error as needed, e.g., show an error message to the user
            alert(`Failed to delete user with email ${selected_user_email}: ${errorMessage}`);
  
            }
          } catch (error) {
            console.error('Error during delete request:', error);
          }
       
      }

      handleEdit = (cust_id) => {
        localStorage.setItem(globalVariables.SELECTED_TECH_ID, cust_id);
        window.location.replace('/edit_cust')
      };

      handleFileChange = (event) => {
        this.setState({
          selectedFile: event.target.files[0]
        });
      }
  
      handleFileUpload = async () => {
        const { selectedFile } = this.state;
        console.log("---selectedFile----", this.state.selectedFile);
        if (!selectedFile) {
          alert("Please select a file first.");
          return;
        }

        let access_tocken = localStorage.getItem(globalVariables.AUTH_TOKEN)
        
        const fileExtension = selectedFile.name.split('.').pop().toLowerCase(); // Check if the file is a CSV
        if (fileExtension !== 'csv') {
            alert('Please upload a CSV file.');
            return;
        }
    
        const formData = new FormData();
        formData.append('file', selectedFile);

        for (let [key, value] of formData.entries()) {
          console.log(`formadata----> ${key}:`, value);
        }
        // console.log("---formdata----", formData);
    
        try {
          const response = await axios.post(`${urls.BASE_URL}/api/elevators/upload_csv/`,formData,  {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': `Bearer ${access_tocken}`
            },
            // body:formData
          });
           console.log("response--->", response);
           
           if (response.status === 201) {
            alert('Data uploaded successfully');
            window.location.reload();
          } else {
            alert('Data already exists');
          }
        } catch (error) {
          alert(error);
        }
      }

      handleDownload = () => {
        // Create a link element
        const link = document.createElement('a');
        // Set the URL to the CSV file in the public folder
        link.href = '/import_csv_for_semapp.csv'; // Make sure the path matches your file location
        // Set the download attribute with the desired file name
        link.download = 'template_for_customer_and_elevator_info.csv'; // The name you want for the downloaded file
        // Append the link to the body
        document.body.appendChild(link);
        // Trigger the download by clicking the link
        link.click();
        // Remove the link from the document
        link.remove();
      };


render(){
    const { customer_list, currentPage, rowsPerPage, searchInput } = this.state;
   
    // Filter elevators based on the search input
    const filteredCustomerList = customer_list.filter((customer) =>
    customer.username.toLowerCase().includes(searchInput.toLowerCase())
    );

    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = filteredCustomerList.slice(indexOfFirstRow, indexOfLastRow);

  return (
        <div>
          <ActivityTabs/>
          {/* <div style={{ display: 'flex', backgroundColor:"#664fa7", color:"white", paddingTop:'2px' }}> */}
          <div className="sem-header-container">
                    <span><h3 style={{height:"30px", paddingLeft:"15px", marginTop:"1px", paddingTop:"5px"}}> Smart Elevator Maintenance &emsp; &gt;&gt; Customers </h3></span>
                    <span style={{ marginLeft: 'auto', marginRight:'10px', marginTop:'9px' }}>
                    <input
                        type="text"
                        id='search-input'
                        size="small"
                        value={searchInput}
                        onChange={this.handleSearchInputChange}
                        placeholder=" search "
                    />
                                        
                    </span>
                    
        </div>
          <br/>
          
            <div style={{display: 'flex', justifyContent: 'space-between', marginRight:'5px', flexWrap: "wrap"}}>
              
              <Link to="/add_customer" >
                <Button id='contactButton' startIcon={<AddIcon />}> Add Customer </Button>
              </Link>
              <div style={{ border: '1px solid black', padding: '7px' }}>
                
                <input 
                  type="file" 
                  onChange={this.handleFileChange} 
                  style={{ display: 'none' }} 
                  ref={fileInput => this.fileInput = fileInput}
                  accept=".csv"
                />
                <Button id='upload_button' onClick={() => this.fileInput.click()}>Upload file &nbsp;<span style={{fontSize:'11px'}}>(CSV-utf8)</span> </Button> &ensp;
                <Button id='import_button' onClick={this.handleFileUpload}>Submit</Button>
                
                <div style={{ display: 'flex', justifyContent:'center', alignItems: 'center', marginTop:'8px' }}>
                  {/* <Button id='download_csv_button' onClick={this.handleDownload}>Download CSV Template</Button> */}
                    <img src={download_icon} id='edit_icon' onClick={this.handleDownload} alt="Download Icon" width='35px' height='35px' style={{cursor:'pointer'}} /> &emsp;<span style={{color:'#663487e1', fontSize:'12px'}}>Click to Download CSV Template</span> 
                </div>
              </div>
            </div>
                <br/>

            <div className="grid-container" style={{marginTop:'15px'}}>
              {currentRows.map((customer) => (
                <div key={customer.user_id} className="elevator-card">
                  <span style={{ display: 'flex', justifyContent: 'space-between'}}>

                  <img width="26" height="26" src={Customericon} alt="engineer"/>
                  <span>
                    <Link>
                    <img width="20" height="20" marginRight="12px"  src={Editicon} id='edit_icon' alt="editIcon" onClick={() => this.handleEdit(customer.user_id)}/>
                    </Link>&ensp;
                    
                    
                    <Link >
                    <img width="20" height="18" src={Deleteicon} id='delete_icon' alt="delIcon" onClick={() => this.handleDelete(customer.email)}/>
                    </Link>
                    </span></span>
                    
                    <h3 
                    // className="hover-effect"
                    >{customer.username}</h3>
                    <p style={{fontSize:'14px'}}>Ticket count : 
                    <Link to={`/customers_raised_issues`} onClick={() => this.handleCustomerClick(customer.user_id, customer.username)} >
                    <b>{customer.ticket_count}</b>
                    </Link>
                    <br/>
                  </p>
                    
                  </div>
                //   )}
              ))}
            </div>
            
          <div style={{ marginTop:'2.2cm', display: 'flex', justifyContent: 'center', marginBottom: '2.2cm' }}>
            <button id ='rowsPerPageButton' onClick={() => this.handleChangePage(currentPage - 1)} disabled={currentPage === 1}>
              {"< Prev"}
            </button>&emsp;
            <span style={{fontSize:'small'}}>
              {`Page ${currentPage} / ${Math.ceil(customer_list.length / rowsPerPage)}`}
            </span>&emsp;
            <button id ='rowsPerPageButton' onClick={() => this.handleChangePage(currentPage + 1)} disabled={currentPage === Math.ceil(customer_list.length / rowsPerPage)}>
              {"Next >"}
            </button>

          </div>
                    
          <div id="copyright-footer">
            <p style={{fontSize:'12px'}}>
            <em>&copy; 2024 Neudeep Technologies. All rights reserved.</em>
            </p>
          </div>
        </div>
   
  );
}
}
export default CustomerTab;
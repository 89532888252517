import React, { Component } from 'react';
import { post } from '../global_files/serverRequests';
import globalVariables from '../global_files/globalVariables';
import { Link } from 'react-router-dom';
import './adminDash.css';
import urls from '../global_files/urls';
import BackArrow from '../assets/icons8-back-arrow.gif';



class DealerRegistration extends Component {

    constructor(props) {
        super(props);
        this.state = {
        user_type: 'DealerUser',
        user_name: '',
        dealer_email:'',
        dealer_password:'',
        conf_dealer_pass:'',
        first_name:'',
        last_name:'',
        mob_num:'',
        
          
        };
      }
    

    
jsonData = () => {
  
    let data = {
        
      "username": this.state.user_name,
      "email": this.state.dealer_email,
      "password1": this.state.dealer_password,
      "password2": this.state.conf_dealer_pass,
      "user_type": this.state.user_type,
      "first_name": this.state.first_name,
      "last_name": this.state.last_name,
      "phoneno": this.state.mob_num,

              
    }
    console.log("-----JSON DATA -------->",data)
    return JSON.stringify(data)
  }
    
  InsertDealerRegistrationInfo = async () => {
    let access_tocken = localStorage.getItem(globalVariables.AUTH_TOKEN)
    // let dealer_email = localStorage.getItem(globalVariables.DEALER_EMAIL)
          console.log("----------access_tocken in insertTechRegistrationInfo----------",access_tocken)
          // console.log("----------dealer_email in insertTechRegistrationInfo----------",dealer_email)
          let headers = {
            'content-type': 'application/json'
            }
    
    let post_data = this.jsonData()

    console.log("post_data in insertCreateTicketInfo---------->",post_data)

    // Parse the JSON string into an object
  let postDataObj = JSON.parse(post_data);

  for (const key in postDataObj) {
    if (postDataObj[key] === null || postDataObj[key] === undefined || postDataObj[key] === "") {
      alert("Please fill in all the required fields.");
      return;
    }
  }
  // let techName = this.state.user_name
  // if (techName.length > 15 ) {
  //   console.log("techname length ---->", techName.length);
  //   alert("User name is too long, enter maximun 15 characters");
  //   return
  //  }  
    try {
      let response = await post(`${urls.BASE_URL}/api/account/registration/`, post_data, headers)
      let password = this.state.dealer_password
      
      console.log("============= successful insert registration info =======", response);
      if (response.email === "A user is already registered with this e-mail address." || response.username === "A user with that username already exists."){
        alert("user name or email already used");
      }
       else if (password.length < 8 ) {
        // alert("Server Problem, try after some time.");
        alert("password is too short, enter minimum 8 characters");
            
       }
       else if (response.non_field_errors == "The two password fields didn't match."){
        alert("The two password fields didn't match.");

      }
      else {
          alert("Dealer registered successfully and Verification e-mail sent to registered mail ID.")
          window.location.replace("/login");
        }
    } catch (error) {
      console.error("Error in Technician Registration:", error);
      alert("An error occurred while Registration. Please try again later.");
    }
  }

  render(){
      return (
        <div>
            <div style={{ display: 'flex', backgroundColor:"#664fa7", color:"white", padding:'8px',  }}>
            <Link to="/login" style={{color:'#fff', textDecoration: 'none', fontSize:'18px'}}><img src={BackArrow} alt="Back arrow" style={{ marginRight: '8px', borderRadius:'35px', width:'30px' }} /></Link>
              <em>Register New Dealer User</em>&emsp; &gt;&gt;      
            </div>
            
            
                <div className="bordered-div" >
                <div className='form-group'>
                      <label htmlFor="user_type" > User Type : &nbsp;</label>
                        <input
                          type="text"
                          id="user_type"
                          name="user_type"
                          readOnly
                          style={{ width: '350px', height: '0.7cm' }}
                          value={this.state.user_type}
                        //   onChange={(evt) => this.setState({ ticket_key: evt.target.value })}
                        />
                  </div>
                  <br/>
                  <div className='form-group'>
                      <label htmlFor="user_name">User name <span style={{color:'red'}}>*</span>: &nbsp;</label>
                      <input
                          type="text"
                          id="user_name"
                          name="user_name"
                          required
                          style={{ width: '350px', height: '0.7cm' }}
                          // placeholder=" fill upto 32 characters only (including spaces)"
                          // placeholder="Enter your user name"
                          value={this.state.user_name}
                          onChange={(evt) => this.setState({ user_name: evt.target.value })}
                        />
                  </div>
                  <br/>
                  <div className='form-group'>
                      <label htmlFor="dealer_email"> Email <span style={{color:'red'}}>*</span> : &nbsp;</label>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          required
                          style={{ width: '350px', height: '0.7cm' }}
                          value={this.state.dealer_email}
                          onChange={(evt) => this.setState({ dealer_email: evt.target.value })}
                        />
                  </div>
                  <br/>
                  <div className='form-group'>
                      <label htmlFor="dealer_password" > Password <span style={{color:'red'}}>*</span> : &nbsp;</label>
                        <input
                          type="password"
                          id="password"
                          name="password"
                          required
                          style={{ width: '350px', height: '0.7cm' }}
                        
                          value={this.state.dealer_password}
                          onChange={(evt) => this.setState({ dealer_password: evt.target.value })}
                        />
                  </div>
                  <br/>
                  <div className='form-group'>
                      <label htmlFor="conf_dealer_pass" > Confirm password <span style={{color:'red'}}>*</span> : &nbsp;</label>
                      <input
                          type="password"
                          id="conf_dealer_pass"
                          name="conf_dealer_pass"
                          required
                          style={{ width: '350px', height: '0.7cm' }}
                          
                          value={this.state.conf_dealer_pass}
                          onChange={(evt) => this.setState({ conf_dealer_pass: evt.target.value })}
                        />
                  </div>
                  <br/>
                  <div className='form-group'>
                      <label htmlFor="first_name" > First name <span style={{color:'red'}}>*</span> : &nbsp;</label>
                      <input
                          type="text"
                          id="first_name"
                          name="first_name"
                          required
                          style={{ width: '350px', height: '0.7cm' }}
                          
                          value={this.state.first_name}
                          onChange={(evt) => this.setState({ first_name: evt.target.value })}
                        />
                  </div>
                  <br/>
                  <div className='form-group'>
                      <label htmlFor="last_name" > Last name <span style={{color:'red'}}>*</span> : &nbsp;</label>
                      <input
                          type="text"
                          id="last_name"
                          name="last_name"
                          required
                          style={{ width: '350px', height: '0.7cm' }}
                          
                          value={this.state.last_name}
                          onChange={(evt) => this.setState({ last_name: evt.target.value })}
                        />
                  </div>
                  <br/>
                  <div className='form-group'>
                      <label htmlFor="mob_num" > Mobile no. <span style={{color:'red'}}>*</span>  :&nbsp;</label>
                      <input
                          type="tel"
                          id="mob_num"
                          name="mob_num"
                          required
                          style={{ width: '350px', height: '0.7cm' }}
                          value={this.state.mob_num}
                          onChange={(evt) => this.setState({ mob_num: evt.target.value })}
                        />
                  </div>
                  <br/>
                </div>
            
            <div style={{ width: '135px', height: '0.6cm', marginLeft:'170px', marginTop:'8px', marginBottom: '100px'  }}>
              {/* <Link to="/all_tech_issues"> */}
              <button style={{backgroundColor:'#664fa7', color:'white', cursor:'pointer'}} 
              onClick={this.InsertDealerRegistrationInfo}
              > Register</button>
              {/* </Link> */}
            </div>
            <div id="copyright-footer">
                <p style={{fontSize:'12px'}}>
                <em>&copy; 2024 Neudeep Technologies. All rights reserved.</em>
                </p>
              </div>
        </div>
      )
  }
}


export default DealerRegistration;
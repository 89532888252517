import React, { Component } from 'react';
// import {  Input, Label } from 'reactstrap';
import { post, get } from '../../global_files/serverRequests';
import globalVariables from "../../global_files/globalVariables";
import { Link } from 'react-router-dom';
import '../adminDash.css';
import GlobalTabpanel from './Tab_panel_global';
import { getFreshToken } from '../../global_files/auth';
import urls from '../../global_files/urls';
import ActivityTabs from "./ActivityTabs";



class AddCustomers extends Component {

  constructor(props) {
    super(props);
    // const today = new Date().toISOString().split('T')[0];
    this.state = {
     
      matches: [],
      content: '',
     

      user_type: 'CustomerUser',
      user_name: '',
      cust_email:'',
      cust_password:'',
      conf_cust_password:'',
      first_name:'',
      last_name:'',
      mob_num:'',
      dealer_email:'',
      dealer_passcode:'',
      
    };
  }

  componentDidMount(){
    this.validateSignedIn();
    this.getDealerInfo();
  }



  validateSignedIn = async () => {
    let is_fresh_token = await getFreshToken()
    if (!is_fresh_token) {
        //this.props.history.replace("/sign_in");
        alert("Your session has expired, please login again!!");
        this.props.history.replace('/login')
        return
    }
} 


jsonData = () => {
  let data = {
      "user_type":this.state.user_type,
      "username":this.state.user_name,
      "email":this.state.cust_email, 
      "password1":this.state.cust_password,
      "password2":this.state.conf_cust_password, 
      "first_name":this.state.first_name,
      "last_name":this.state.last_name,
      "admin_email": this.state.dealer_email,
      "admin_passcode": this.state.dealer_passcode,
      "is_staff":0,
      "is_verified": false,
      "phoneno": this.state.mob_num
  }
  console.log("-----JSON DATA -------->",data)
  return JSON.stringify(data)
}
  
  insertCustRegistrationInfo = async () => {
    let access_tocken = localStorage.getItem(globalVariables.AUTH_TOKEN)
          console.log("----------access_tocken in issue list----------",access_tocken)
          let headers = {
            'content-type': 'application/json'
          }
    
    let post_data = this.jsonData()

    console.log("post_data in insertCreateTicketInfo---------->",post_data)

    // Parse the JSON string into an object
  let postDataObj = JSON.parse(post_data);

  for (const key in postDataObj) {
    if (postDataObj[key] === null || postDataObj[key] === undefined || postDataObj[key] === "") {
      alert("Please fill in all the required fields.");
      return;
    }
  }
  // let custName = this.state.user_name
  // if (custName.length > 15 ) {
  //   console.log("techname length ---->", custName.length);
  //   alert("User name is too long, enter maximun 15 characters");
  //   return
  //  }  
  try {
    let response = await post(`${urls.BASE_URL}/api/account/registration/`, post_data, headers)
    
    let password = this.state.cust_password
    console.log("============= successful insert registration info =======", response);
    if (response.email == "A user is already registered with this e-mail address." || response.username == "A user with that username already exists."){
    
      alert("user name or email already used");
    }
     else if (password.length < 8 ) {
      // alert("Server Problem, try after some time.");
      alert("password is too short, enter minimum 8 characters");
          
     } 
     else if (response.non_field_errors == "The two password fields didn't match."){
      alert("The two password fields didn't match.");
    }
    else {
        alert("Customer registered successfully and Verification e-mail sent.")
        window.location.replace("/customers");
      }
  } catch (error) {
    console.error("Error in customer Registration:", error);
    alert("An error occurred while Registration. Please try again later.");
  }
}


  getDealerInfo= async ()=>{
    let access_tocken = localStorage.getItem(globalVariables.AUTH_TOKEN)
    let dealer_uuid = localStorage.getItem(globalVariables.USER_ID)
    console.log("----------access_tocken in employee list----------",access_tocken)
    let headers = {
      // 'content-type': 'application/json'
      'content-Type': 'application/json; charset=UTF-8',
      'Authorization': `Bearer ${access_tocken}`,
  }
  let response = await get(`${urls.BASE_URL}/api/users/dealer_user/${dealer_uuid}/`, headers) 
        console.log("-----------------getDealerInfo-----------------",response);
        this.setState({dealer_email : response.dealer_email})
        console.log("----------dealer_email----------",this.state.dealer_email);
    

}
  
  render(){
  
  return (
    <div>
        <ActivityTabs/>
                <div className="sem-header-container">
                    <span><h3 style={{height:"30px", paddingLeft:"15px", marginTop:"1px", paddingTop:"5px"}}> Smart Elevator Maintenance &emsp; &gt;&gt; 
                    <Link id="navigation" to="/customers">Customers</Link> &gt;&gt; 
                    <Link id="navigation" >Add Customer</Link> 
                    </h3></span>
                    <span style={{ marginLeft: 'auto', marginRight:'5px',marginTop:"9px" }}>
                        <input
                            type="text"
                            id='search-input'
                            size="small"
                            placeholder=" search "
                        />
                    </span>
                </div>
        <GlobalTabpanel/>
            <div className="bordered-div" >
            <div>
                  <label htmlFor="user_type" style={{marginLeft : '95px'}}> User Type : &emsp;&emsp;&emsp;&emsp;</label>
                    <input
                      type="text"
                      id="user_type"
                      name="user_type"
                      readOnly
                      style={{ width: '350px', height: '0.7cm' }}
                      value={this.state.user_type}
                    />
               </div>
               <br/>
               <div style={{ marginLeft:'30px'}}>
                  <label htmlFor="user_name" style={{marginLeft : '65px', marginRight:'30px'}}>User name <span style={{color:'red'}}>*</span>&nbsp;&nbsp;&nbsp;&nbsp;: &nbsp;</label>
                  <input
                      type="text"
                      id="user_name"
                      name="user_name"
                      required
                      style={{ width: '350px', height: '0.7cm' }}
                      value={this.state.user_name}
                      onChange={(evt) => this.setState({ user_name: evt.target.value })}
                    />
               </div>
               <br/>
               <div>
                  <label htmlFor="cust_email" style={{marginLeft : '95px'}}> Email <span style={{color:'red'}}>*</span>&ensp; : &nbsp;&emsp;&emsp;&emsp;&emsp;&ensp;</label>
                    <input
                      type="email"
                      id="email"
                      name="cust_email"
                      required
                      style={{ width: '350px', height: '0.7cm' }}
                      value={this.state.cust_email}
                      onChange={(evt) => this.setState({ cust_email: evt.target.value })}
                    />
               </div>
               <br/>
               <div>
                  <label htmlFor="cust_password" style={{marginLeft : '95px'}}> Password <span style={{color:'red'}}>*</span>&emsp; : &nbsp;&emsp;&emsp;&nbsp;</label>
                    <input
                      type="password"
                      id="cust_password"
                      name="cust_password"
                      required
                      style={{ width: '350px', height: '0.7cm' }}
                      
                      value={this.state.cust_password}
                      onChange={(evt) => this.setState({ cust_password: evt.target.value })}
                    />
               </div>
               <br/>
               <div>
                  <label htmlFor="conf_cust_password" style={{marginLeft : '95px'}}> Confirm password <span style={{color:'red'}}>*</span> &nbsp;&nbsp;&nbsp; : &nbsp;</label>
                  <input
                      type="password"
                      id="conf_cust_password"
                      name="conf_cust_password"
                      required
                      style={{ width: '320px', height: '0.7cm' }}
                      
                      value={this.state.conf_cust_password}
                      onChange={(evt) => this.setState({ conf_cust_password: evt.target.value })}
                    />
               </div>
               <br/>
               <div>
                  <label htmlFor="first_name" style={{marginLeft : '95px'}}> First name <span style={{color:'red'}}>*</span> &nbsp;&nbsp;&nbsp; : &nbsp;&emsp;&ensp;</label>
                  <input
                      type="text"
                      id="first_name"
                      name="first_name"
                      required
                      style={{ width: '350px', height: '0.7cm' }}
                     
                      value={this.state.first_name}
                      onChange={(evt) => this.setState({ first_name: evt.target.value })}
                    />
               </div>
               <br/>
               <div>
                  <label htmlFor="last_name" style={{marginLeft : '95px'}}> Last name <span style={{color:'red'}}>*</span> &nbsp;&nbsp;&nbsp; : &nbsp;&emsp;&ensp;</label>
                  <input
                      type="text"
                      id="last_name"
                      name="last_name"
                      required
                      style={{ width: '350px', height: '0.7cm' }}
                      
                      value={this.state.last_name}
                      onChange={(evt) => this.setState({ last_name: evt.target.value })}
                    />
               </div>
               <br/>
               <div>
                  <label htmlFor="mob_num" style={{marginLeft : '95px'}}> Mobile no. <span style={{color:'red'}}>*</span> &nbsp;&nbsp;&nbsp; :&nbsp;&emsp;&ensp;</label>
                  <input
                      type="tel"
                      id="mob_num"
                      name="mob_num"
                      required
                      style={{ width: '350px', height: '0.7cm' }}
                      value={this.state.mob_num}
                      onChange={(evt) => this.setState({ mob_num: evt.target.value })}
                    />
               </div>
               <br/>
               <div>
                  <label htmlFor="dealer_email" style={{marginLeft : '95px'}}> Dealer email <span style={{color:'red'}}>*</span> &nbsp;&nbsp;&nbsp; : &nbsp;&nbsp;&nbsp;</label>
                  <input
                      type="email"
                      id="dealer_email"
                      name="dealer_email"
                      required
                      style={{ width: '350px', height: '0.7cm' }}
                      readOnly
                      value={this.state.dealer_email}
                    />
               </div>
               <br/>
               <div>
                  <label htmlFor="dealer_passcode" style={{marginLeft : '95px'}}> Dealer passcode <span style={{color:'red'}}>*</span> &nbsp;&nbsp;&nbsp; : &nbsp;</label>
                  <input
                      type="text"
                      id="dealer_passcode"
                      name="dealer_passcode"
                      required
                      style={{ width: '330px', height: '0.7cm' }}
                      
                      value={this.state.dealer_passcode}
                      onChange={(evt) => this.setState({ dealer_passcode: evt.target.value })}
                    />
               </div>
               <br/>
            </div>
         
         <div style={{ width: '130px', height: '0.5cm', marginLeft:'170px', marginTop:'8px', marginBottom: '100px'  }}>
          <button style={{backgroundColor:'#664fa7', color:'white', cursor:'pointer'}} 
          onClick={this.insertCustRegistrationInfo}
          > Add Customer</button>
         </div>
          <div id="copyright-footer">
            <p style={{fontSize:'12px'}}>
            <em>&copy; 2024 Neudeep Technologies. All rights reserved.</em>
            </p>
          </div> 
    </div>
    
    
    
  )
}
}


export default AddCustomers;
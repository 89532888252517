import React from 'react';
import '../adminDash.css';
import { Link } from 'react-router-dom';
import CompanyLogo from '../../assets/Neudeep_logo.jpeg';


function Homepage() {
  return (
    <div className="Homepage">
      <header className="Homepage-header">
        <span className="header-left">
          <img width="160" height="60" marginRight="12px"  src={CompanyLogo}  alt="editIcon"/>
        </span>
        <span> <h1>Smart Elevator Maintenance</h1></span>
        <span className="header-right">
        <Link to="/login" >
          <button className="login-button">Login</button>
        </Link>&nbsp;
        {/* <Link to="/delete_account" >
          <button className="delete-account-button">Delete Account</button>
        </Link> */}
        </span>
      </header>
      <nav className="Homepage-nav">
        <ul>
        <Link to="/about" className="link-no-underline1"><li><em>Home</em></li></Link>
        <Link to="/pricing_details" className="link-no-underline1"><li><em>Pricing Details</em></li></Link>
        <Link to="/privacy_policy" className="link-no-underline1"><li><em>Privacy Policy</em></li></Link>
        {/* <Link to="/delete_account" className="link-no-underline1"><li><em>Delete Account</em></li></Link> */}
        <Link to="https://www.neudeep.com/Contactneudeep.php" className="link-no-underline1"><li><em>Contact Us</em></li></Link>
        </ul>
      </nav>
      <main className="App-content">
      
      </main>
      <div className='Playstore_floating_footer'>
        <span>
        <p style={{color:'white'}}> Try SEM Android App Now...</p>
        </span>&nbsp;
        <a href='https://play.google.com/store/apps/details?id=com.semapp.semappFolder'>
            <img src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg" alt = "GooglePlayStore" className="playstore-badge"/>
        </a>
        
      </div>
    </div>
  );
}

export default Homepage;
